import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row } from 'components/atoms';
import LogoItems from 'components/molecules/LogoItems';

const LogosIndustry = ({ data, spacer, className, wide }) => {
  const { logos, stretch_logos_section } = data;

  return (
    <Section
      className={cn('logos-industry', spacer && 'logos-industry_spacer', [
        className,
      ])}
    >
      <Container>
        <Row>
          <LogoItems
            data={logos}
            lg="9"
            col="12"
            offset="0"
            stretch={stretch_logos_section}
            className={cn(
              `logotypes_industry col-lgplus-${wide ? '7' : '6'}`,
              'offset-lg-2'
            )}
          />
        </Row>
      </Container>
    </Section>
  );
};

LogosIndustry.defaultProps = {
  className: '',
  wide: false,
};

LogosIndustry.propTypes = {
  data: PropTypes.object.isRequired,
  spacer: PropTypes.bool,
  className: PropTypes.string,
  wide: PropTypes.bool,
};

export default LogosIndustry;
